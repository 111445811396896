.box-tab{
  
}
.box-tab .tab-head{
  display: flex;
}
.box-tab .tab-head > div{
  border: 1px solid var(--primary-bgh);
  border-bottom: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
}
.box-tab .tab-head > div.active{
  background-color: var(--primary-bgh);
  color: var(--primary-color);
}
.box-tab .tab-content{
  border: 1px solid var(--primary-bgh);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px 15px;
}