.website-about{
    background: #f6f6f6;
}
.website-about h3 {
    font-size: 22px;
    margin: 0 0 20px;
}
.website-about h2 {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 15px;
}
.website-about h2::after {
    position: absolute;
    content: "";
    background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
    height: 4px;
    width: 60px;
    bottom: 0;
    left: 0;
}
.website-about li {
    margin-bottom: 6px;
    margin-left: 6px;
    list-style: none;
    list-style-type: none;
    padding: 0;
}
.website-about  li:before {
    content: "✔";
    color: #5ca9fb;
    font-size: 11px;
    font-weight: 300;
    padding-right: 8px;
}
.website-about img {
    width: 100%;
    height: auto;
    margin-top: 10px;
    background: #fff;
    border-right: 0;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}
.website-about p {
    line-height: 24px;
    margin: 30px 0;
}

.website-about-img{
    width: 85%;
    height: auto;
  }

.website-about .purpose-action{
    margin-bottom: 15px;
}