.website-purpose{
    background: #f6f6f6;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}

.website-group{
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.website-purpose .section-title {
    display: flex;
    align-items: center;
    flex-direction: column;
}
  
.website-purpose .section-title h2 {
    position: relative;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    text-transform: uppercase;
}
  
.website-purpose .section-title p {
    font-size: 18px;
}

.website-purpose .section-title h2::after {
    position: absolute;
    content: "";
    background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
    height: 4px;
    width: 60px;
    bottom: 0;
    margin-left: -30px;
    left: 50%;
}

.website-purpose h4 {
    margin: 5px 0;
}

.purpose-img {
    width: 50%;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}

.img-align{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.group{
    padding: 100px 0;
}

.caption {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000;
}

.caption p{
    color: #888;
}