#root-alert {
  height: 0;
  position: fixed;
  top: 10px;
  right: 15px;
  z-index: 999;
}
.box-alert {
  background-color: var(class);
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  color: #333;

  display: flex;
  font-size: 1.1em;
  justify-content: space-between;
  margin-bottom: 8px;
  min-width: 250px;
}
.box-alert > div {
  padding: 8px 8px 8px 15px;
}
.alert-close {
  align-items: center;
  align-self: flex-start;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: #333;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  justify-content: center;
  min-height: 25px;
  min-width: 25px;
  margin: 4px 4px 0 0;
  padding: 0;
}
.alert-close:hover {
  background-color: #fff;
}
.alert-close:focus {
  position: relative;
  top: 1px;
  outline: none;
}

.alert-primary {
  background-color: var(--primary-bg);
  border: 1px solid var(---primary-bgh);
  color: var(--primary-color);
}
.alert-primary .alert-close {
  background-color: var(--primary-bg);
  color: var(--primary-color);
}
.alert-primary .alert-close:hover {
  background-color: var(--primary-bgh);
  color: var(--primary-color);
}

.alert-secondary {
  background-color: var(--secondary-bg);
  border: 1px solid var(--secondary-bgh);
  color: var(--secondary-color);
}
.alert-secondary .alert-close {
  background-color: var(--secondary-bg);
  color: var(--secondary-color);
}
.alert-secondary .alert-close:hover {
  background-color: var(--secondary-bgh);
  color: var(--secondary-color);
}

.alert-success {
  background-color: var(--success-bg);
  border: 1px solid var(--success-bgh);
  color: var(--success-color);
}
.alert-success .alert-close {
  background-color: var(--success-bg);
  color: var(--success-color);
}
.alert-success .alert-close:hover {
  background-color: var(--success-bgh);
  color: var(--success-color);
}

.alert-error {
  background-color: var(--error-bg);
  border: 1px solid var(--error-bgh);
  color: var(--error-color);
}
.alert-error .alert-close {
  background-color: var(--error-bg);
  color: var(--error-color);
}
.alert-error .alert-close:hover {
  background-color: var(--error-bgh);
  color: var(--error-color);
}

.alert-warning {
  background-color: var(--warning-bg);
  border: 1px solid var(--warning-bgh);
  color: var(--warning-color);
}
.alert-warning .alert-close {
  background-color: var(--warning-bg);
  color: var(--warning-color);
}
.alert-warning .alert-close:hover {
  background-color: var(--warning-bgh);
  color: var(--warning-color);
}
