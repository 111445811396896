.menu {
  /* border-top: 1px solid var(--background-sidebar-h); */
}
.menu button {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--background-sidebar-h);
  color: var(--color-sidebar);
  cursor: pointer;
  padding: 10px 15px;
  transition: all 0.2s;
  text-align: left;
  width: 100%;
}
.menu button:hover,
.menu button.active {
  background-color: var(--background-sidebar-h);
  color: var(--color-sidebar-h);
  transition: all 0.2s;
}