.website-solutions{
  background: linear-gradient(to right, #272727 0%, #1d1d1d 100%);
  color: #fff;
  
}

.website-solutions-chart{
  width: 85%;
  height: auto;
}

.website-solutions .section-chart {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.website-solutions .section-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.website-solutions .section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
}

.website-solutions .section-title p {
  font-size: 18px;
}

.website-solutions .section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}