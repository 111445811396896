.website-contact {
  /* background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%); */
  background: url(../img/bg-contact.png) center center no-repeat;
  background-size: cover;
  color: rgba(255, 255, 255, 0.75);
  /* padding: 100px 0 60px; */
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.website-contact .section-title {
  display: flex;
  flex-direction: column;
}

.website-contact .section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
}

.website-contact .section-title p {
  font-size: 18px;
}

.website-contact .section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}


  #contact h3 {
    color: #fff;
    margin-top: 10px;
    margin-bottom: 25px;
    font-weight: 400;
  }
  #contact form {
    padding-top: 20px;
  }
  #contact .text-danger {
    color: #cc0033;
    text-align: left;
  }
  #contact .btn-custom {
    margin: 30px 0;
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
  }
  #contact .btn-custom:hover {
    color: #1f386e;
    background: #fff;
  }
  label {
    font-size: 12px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    float: left;
  }
  #contact .form-control {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #444;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ddd;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
  #contact .form-control:focus {
    border-color: #999;
    outline: 0;
    -webkit-box-shadow: transparent;
    box-shadow: transparent;
  }
  .form-control::-webkit-input-placeholder {
    color: #777;
  }
  .form-control:-moz-placeholder {
    color: #777;
  }
  .form-control::-moz-placeholder {
    color: #777;
  }
  .form-control:-ms-input-placeholder {
    color: #777;
  }
  #contact .contact-item {
    margin: 20px 0;
  }
  #contact .contact-item span {
    color: rgba(255, 255, 255, 1);
    margin-bottom: 10px;
    display: block;
  }
  #contact .contact-item i.fa {
    margin-right: 10px;
  }
  #contact .social {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding-top: 50px;
    margin-top: 50px;
    text-align: center;
  }
  #contact .social ul li {
    display: inline-block;
    margin: 0 20px;
  }
  #contact .social i.fa {
    font-size: 22px;
    width: 48px;
    height: 48px;
    padding: 12px 0;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 50%;
    transition: all 0.3s;
  }
  #contact .social i.fa:hover {
    color: #608dfd;
    background: #fff;
  }