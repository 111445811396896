:root {
  --primary-bg: #ffcb00;
  --primary-bgh: #e2b603;
  --primary-color: #333;

  --secondary-bg: #6372ff;
  --secondary-bgh: #5262f0;
  --secondary-color: #fff;

  --success-bg: #008327;
  --success-bgh: #00561a;
  --success-color: #fff;

  --error-bg: #940404;
  --error-bgh: #640101;
  --error-color: #fff;

  --warning-bg: #c47500;
  --warning-bgh: #a06000;
  --warning-color: #fff;

  --scrollbar-track: #f1f1f1;
  --scrollbar-thumb: #3e5055;
  --scrollbar-thumbh: #2f3d41;

  --background-page: #fff;
  --color-page: #333;
  --background-container: #f1f1f1;

  --background-navbar-default: #fff;
  --color-navbar-default: #555;
  --background-navbar-brand: #333;

  --background-header: #000;
  --background-header-h: #272727;
  --background-header-b: #70b131;
  --color-header: #fff;

  --background-sidebar: #ececec;
  --background-sidebar-h: #70b131;
  --color-sidebar: #333;
  --color-sidebar-h: #fff;

  --background-footer: #333;
  --background-footer-h: #6ca73c;
  --color-footer: #fff;

  --input-bg: #fff;
  --input-bgh: #666;
  --input-color: #333;

  --list-bg: transparent;
  --list-bg-h: #ececec;
  --list-color: #333;
  --list-color-h: #333;

  --modal-bg: #fff;
  --modal-bgo: #000000c9;
  --modal-bgh: #444;
  --modal-color: #333;
}
