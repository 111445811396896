.box-btn {
  display: flex;
  justify-content: flex-end;
}
.box-btn .btn + .btn{
  margin-left: 10px;
}

#box-app {
  display: flex;
  width: 100%;
}

/* Navigation */

#website-navbar-default {
  background-color: var(--background-navbar-default);
  color: var(--color-navbar-default);
  /*border-bottom: 2px solid var(--background-header-b);*/
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
  padding: 15px;
}

#website-navbar-brand{
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: var(--background-navbar-brand); 
  text-transform: capitalize;
}

.website-logo{
  width: auto;
  max-height: 60px;
  height: 100%;
}

#website-header > div {
  padding: 0 15px;
}

.btn-menu {
  background-color: #fff;
  border: 2px solid #333;
  border-radius: 5px;
  font-size: 26px;
  padding: 7px 3px 3px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn-menu:hover {
  background-color: #f1f1f1;
}

.btn-navbar {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: 400;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0px;
  color: currentColor;
  cursor: pointer;
  display: inline-block;
  /* font-size: 1.5em; */
  padding: 8px 15px;
  transition: all 0.15s;
}

.btn-navbar.active {
  background: transparent;
}
.btn-navbar:after {
  display: block;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  content: "";
  transition: width 0.2s;
}

#menu.navbar-default .navbar-nav > li > a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  content: "";
  transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > a:hover:after {
  width: 100%;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a:after,
.navbar-default .navbar-nav > .active > a:hover:after,
.navbar-default .navbar-nav > .active > a:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
  content: "" !important;
  transition: width 0.2s !important;
}

#website-header button {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0px;
  color: currentColor;
  cursor: pointer;
  display: inline-block;
  /* font-size: 1.5em; */
  padding: 8px 15px;
  transition: all 0.15s;
}
#website-header button.btFlex{
  display: flex;
  align-items: center;
}
#website-header button svg {
  display: flex;
  font-size: 1.5em;
}
#website-header button:hover,
#website-header button.open {
  background-color: var(--background-header-h);
}

#box-footer {
  background-color: var(--background-footer);
  color: var(--color-footer);
  padding: 10px 15px;
}

#box-sidebar-left {
  background-color: var(--background-sidebar);
  border-right: 1px solid var(--background-sidebar-h);
  overflow: auto;
  height: 100%;
  width: 300px;
}

#box-sidebar-right {
  background-color: var(--background-sidebar);
  border: 1px solid var(--background-sidebar-h);
  display: none;
  overflow: auto;
  height: auto;
  max-height: 100%;
  min-width: 200px;
  position: absolute;
  right: 0;
}
#box-sidebar-right.open-sidebar {
  display: block;
}

#box-sidebar-right .usuario-logado {
  padding: 10px 15px;
  color: var(--color-sidebar);
}

#box-sidebar-right .usuario-logado small {
  display: block;
}

#box-sidebar-right .sair-sistema {
  padding: 10px 15px;
}

#box-container {
  overflow: auto;
  height: 100%;
  width: 100%;
}
#box-container > div {
  color: var(--color-page);
  min-height: calc(100% - 67px);
  padding: 0 0;

  /* background-color: var(--background-sidebar);
  border: 1px solid var(--background-sidebar-h);
  border-radius: 4px;
  margin: 0 15px 15px; */
}
#box-container .titulo {
  color: var(--color-page);
  font-size: 1.7rem;
  margin: 0 0 10px 0;
  border-bottom: 2px solid var(--primary-bg);
}

#box-container .content-container{
  width: 100%;
  max-width: 992px;
  margin: 0px auto;
  padding: 20px 15px;
}

/* #website-header {
  grid-area: header;
}
#box-sidebar-left {
  grid-area: sidebarLeft;
  overflow: auto;
  height: 100%;
}
#box-container {
  grid-area: container;
  overflow: auto;
  height: 100%;
}

#root {
  display: grid;
  grid-template-areas: 'header header header' 'sidebarLeft container container';
} */

@media (min-width: 780px) {
  #website-header .btn-menu {
    display: none;
  }
}

@media (max-width: 780px) {
  #box-sidebar-left {
    position: absolute;
    z-index: 2;
  }
  #box-sidebar-left {
    display: none;
  }
  #box-sidebar-left.open-sidebar {
    display: block;
  }
}

#box-container .box-pesquisa {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
#box-container .box-pesquisa > div:first-child {
  min-width: 50%;
}
#box-container .box-pesquisa .adicionar {
  margin-bottom: 15px;
}





@media (min-width: 780px) {
  .btn-menu {
    display: none;
  }
  .btn-navbar.active:after,
  .btn-navbar:focus:after,
  .btn-navbar:hover:after{
    content: "";
    left: 0;
    bottom: -1px;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    transition: width 0.2s;
    display: block;
    height: 2px;
    width: 100%;
  }
}

@media (max-width: 780px) {
  .box-menu {
    display: none;
    position: absolute;
    top: 95px;
    right: 0;
    background: #fff;
    border: 1px solid #333;
    z-index: 99;
  }
  .box-menu button {
    display: block;
    width: 100%;
    min-width: 200px;
    border-bottom: 1px solid;
  }
  .box-menu.open {
    display: block;
  }
}
