/* .website-home{
  background: url(../img/tech-business.jpg) center center no-repeat;
  height: calc(100vh - 96px);
  background-size: cover;
  width: 100%;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.website-home h1 {
  font-family: 'Raleway', sans-serif;
  color: #000;
  font-size: 75px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
}

.website-home h2 {
  margin: 0;
  text-transform: uppercase;
}

.text-yellow{
  color:#efc900;
}

.website-home p {
  /* color: #000; */
  font-size: 25px;
  font-weight: 380;
  line-height: 30px;
  margin: 0 auto;
}

.website-home:before {
  content: '';
  /* display: inline-block; */
  vertical-align: middle;
  height: 100%;
}

.website-home .box-info{
  background-color: #00000085;
  padding: 25px 0;
  margin-top: 30px;
}