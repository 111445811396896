.box-scrool{
  overflow-y: hidden;
  overflow-x: auto;
}
.list-box {
  border-top: 1px solid var(--list-bg-h);
  border-spacing: 0;
  color: var(--list-color);
  position: relative;
  width: 100%;
}
.list-box td {
  border-bottom: 1px solid var(--list-bg-h);
  padding: 2px 10px;
}
.list-box thead td {
  background-color: var(--list-bg-h);
  color: var(--list-color-h);
  padding: 8px 10px;
}

.list-box tbody tr:hover td {
  background-color: var(--list-bg);
}
.list-box .actions {
  white-space: nowrap;
  width: 1%;
}

.list-box .actions button {
  margin-left: 10px;
}
.list-box .actions button:first-child {
  margin-left: 0px;
}
