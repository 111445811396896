.form-box {
  position: relative;
  margin-bottom: 15px;
  /* padding-top: 10px; */
}
.form-box label {
  color: var(--input-color);
  display: block;
  font-size: 1em;
  padding-bottom: 5px;
}

.form-box .label-input {
  background-image: linear-gradient(#00000000, #00000000, #00000000, var(--input-bg), #00000000);
  /* background-color: var(--input-bg); */
  color: var( --input-color);
  display: inline-block;
  font-size: 1em;
  padding: 0px 2px;
  /* position: absolute;
  top: -3px;
  left: 13px; */
  z-index: 2;
}

.form-box input,
.form-box .input,
.form-box textarea {
  background-color: var(--input-bg);
  border: 1px solid var(--input-bgh);
  border-radius: 8px;
  color: var(--input-color);
  display: block;
  font-size: 1em;
  margin: 0;
  padding: 8px 15px;
  width: 100%;
  min-height: 35px;
}

.form-box textarea {
  min-height: 100px;
  min-width: 100%;
  max-width: 100%;
  border-radius: 10px;
}

.form-box input:focus,
.form-box textarea:focus {
  outline: none;
}
.form-box input[type=file]{
  padding: 5px 15px 5px 5px;
}
.form-box input[type=date],
.form-box input[type=time]{
  padding: 5px 5px 5px 15px;
}

.form-box > div {
  position: relative;
}
.form-box .input-actions {
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  right: 4px;
  top: 0;
}

.form-box .input-actions button {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 50px;
  color: var(--input-color);
  cursor: pointer;

  display: flex;
  font-size: 20px;
  justify-content: center;
  min-height: 30px;
  min-width: 30px;
}

.form-box .input-actions button:hover {
  background-color: var(--secondary-bgh);
  color: var(--secondary-color);
}

.form-box .input-actions button:active {
  position: relative;
  top: 1px;
}
.form-box .input-actions button:focus {
  outline: none;
}

.form-box .textarea-actions {
  height: 100%;
  position: absolute;
  right: 4px;
  top: 4px;
}

.form-box .textarea-actions button {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: var(--secondary-color);
  cursor: pointer;

  display: flex;
  font-size: 14px;
  height: 30px;
  justify-content: center;
  width: 30px;
}

.form-box .textarea-actions button:hover {
  background-color: var(--secondary-bgh);
}

.form-box .textarea-actions button:active {
  position: relative;
  top: 1px;
}
.form-box .textarea-actions button:focus {
  outline: none;
}

button.select-selected {
  background-color: var(--input-bg);
  border: 1px solid var(--input-bgh);
  border-radius: 50px;
  color: var(--input-color);
  cursor: pointer;
  display: flex;
  font-size: 1em;
  justify-content: space-between;
  padding: 8px 15px;
  width: 100%;
}
button.select-selected:after {
  content: '';
  border-bottom: 2px solid var(--input-color);
  border-left: 2px solid var(--input-color);
  height: 8px;
  width: 8px;
  transform: rotate(-50deg);
  transition: all 0.1s;
}
button.select-selected.open:after {
  margin-top: 4px;
  transform: rotate(135deg);
  transition: all 0.1s;
}
.select-filter {
  position: relative;
  margin: 6px 10px;
}

.select-all {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: 10px 10px 6px 10px;
  position: relative;
}

.select-box {
  background-color: var(--background-container);
  border: 1px solid var(--input-bgh);
  border-radius: 4px;
  overflow: auto;
  position: absolute;
  max-height: 300px;

  top: 100%;
  width: 100%;
  z-index: 3;
}
.select-box .select-options {
  position: relative;
}

.select-box .select-options > div {
  border-top: 1px solid var(--input-bgh);
  color: var(--input-color);
  cursor: pointer;
  padding: 6px 10px;
  position: relative;
}
.select-box .select-options > div:hover {
  background-color: var(--primary-bgh);
  color: var(--primary-color);
}
.select-box .select-options > div.selected {
  background-color: var(--primary-bgh);
  color: var(--primary-color);
}
.select-box .select-options > div:active {
  position: relative;
  top: 1px;
}

.select-box .select-options > div {
  align-items: center;
  display: flex;
}

.select-box.multiselect .select-options > div.selected {
  background-color: var(--input-bg);
  color: var(--input-color);
}
.select-box.multiselect .checkelement {
  border: 1px solid var(--input-bgh);
  border-radius: 4px;
  color: var(--color);
  cursor: pointer;
  display: inline-block;
  height: 18px;
  margin-right: 8px;
  position: relative;
  width: 18px;
}
.select-box.multiselect div.selected .checkelement {
  background-color: var(--primary-bg);
}
.select-box.multiselect div.selected .checkelement:after {
  content: '';
  border-bottom: 2px solid var(--secondary-color);
  border-left: 2px solid var(--secondary-color);
  height: 9px;
  left: 1px;
  margin-right: 8px;
  position: absolute;
  top: 0px;
  width: 14px;
  transform: rotate(-50deg);
}

.check-box {
  align-items: center;
  color: var(--input-color);
  cursor: pointer;
  display: inline-flex;
  font-size: 1em;
  line-height: 34px;
  margin-left: 10px;
}
.check-box:first-child {
  margin-left: 0px;
}
.check-box .checkbox {
  border: 1px solid var(--input-bgh);
  border-radius: 4px;
  display: inline-block;
  height: 20px;
  margin-right: 8px;
  position: relative;
  transition: all 0.3s;
  width: 20px;
}
.check-box.checked .checkbox {
  background-color: var(--primary-bg);
  transition: all 0.3s;
}
.check-box.checked .checkbox:after {
  content: '';
  border-bottom: 3px solid var(--secondary-color);
  border-left: 3px solid var(--secondary-color);
  height: 10px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 15px;
  transform: rotate(-50deg);
  transition: all 0.3s;
}

.check-box .switch {
  border: 1px solid var(--input-bgh);
  border-radius: 50px;
  display: inline-block;
  height: 20px;
  margin-right: 8px;
  position: relative;
  transition: all 0.3s;
  width: 45px;
}
.check-box.checked .switch {
  background-color: var(--primary-bg);
  transition: all 0.3s;
}
.check-box .switch:after {
  content: '';
  background-color: var(--primary-color);
  border: 1px solid var(--input-bgh);
  border-radius: 50%;
  box-shadow: inset 0px 0px 3px 0px var(--input-bgh);
  height: 20px;
  left: 0px;
  position: absolute;
  top: -1px;
  transition: all 0.3s;
  width: 20px;
}
.check-box.checked .switch:after {
  margin-left: 25px;
  transition: all 0.3s;
}

.radio-box {
  align-items: center;
  color: var(--input-color);
  cursor: pointer;
  display: inline-flex;
  line-height: 34px;
  margin-left: 10px;
}
.radio-box:first-child {
  margin-left: 0px;
}
.radio-box .checkbox {
  border: 1px solid var(--input-bgh);
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  margin-right: 8px;
  position: relative;
  transition: all 0.3s;
  width: 20px;
}
.radio-box.checked .checkbox {
  background-color: var(--primary-bg);
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 2px var(--primary-color);
  transition: all 0.3s;
}

.datepicker .box-calendario {
  position: absolute;
  z-index: 3;
}
.box-calendario {
  background-color: var(--input-bg);
  border: 1px solid var(--input-bgh);
  border-radius: 4px;
}
.box-calendario table {
  width: 100%;
}
.box-calendario thead td {
  text-align: center;
}
.box-calendario tbody td.mes-aleatorio {
  opacity: 0.3;
}
.box-calendario td.mes-atual {
  opacity: 1;
}
.box-calendario td.dia-atual {
  opacity: 1;
}
.box-calendario td.dia-atual .btn.normal.default {
  color: var(--primary-bg);
}

.box-calendario tbody td button {
  width: 100%;
}
.datepicker button.select-selected {
  padding: 8px 8px 8px 15px;
}
.datepicker button.select-selected:after {
  content: '';
  display: none;
}
.box-calendario .btn-meses button {
  width: calc(100% / 3);
}


.box-calendario tbody td button.atual {
  background-color: var(--atual-bg);
  color: var(--atual-color);
}
.box-calendario tbody td button.disponivel {
  background-color: var(--disponivel-bg);
  color: var(--disponivel-color);
}
.box-calendario tbody td button.ocupado {
  background-color: var(--ocupado-bg);
  color: var(--ocupado-color);
}
.box-calendario tbody td button.compromisso {
  background-color: var(--compromisso-bg);
  color: var(--compromisso-color);
}



.form-box .label-input > span {
  color: var(--error-bgh);
}
.form-box.erro input,
.form-box.erro textarea,
.form-box.erro button.select-selected {
  border: 1px solid var(--error-bgh);
}
.form-box.success input,
.form-box.success textarea,
.form-box.success button.select-selected {
  border: 1px solid var(--primary-bgh);
}
.form-box .campo-obrigatorio{
  color: var(--error-bgh);
  padding: 0 15px;
  font-size: 11px;
  display: block;
}


.form-box .ck.ck-editor__main>.ck-editor__editable{
  height: 200px;
}