.box-progress-bar{
  margin-bottom: 15px;
}
.box-progress-bar .bar{
  border: 1px solid var(--primary-bgh);
  border-radius: 4px;
  overflow: hidden;
}
.box-progress-bar .bar > div{
  height: 15px;
  background-color: var(--primary-bg);
}
.box-progress-bar .cal-group div:nth-child(1){
  padding-right: 10px;
  vertical-align: middle;
}
.box-progress-bar .cal-group div:nth-child(3){
  padding-left: 10px;
  vertical-align: middle;
}